import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import "../styles/components/header.scss";

export default function Header() {
  const { pathname } = useLocation();
  return (
    <header
      className={
        pathname === "/" || pathname === "/error"
          ? "header--unactive"
          : "header--active"
      }
    >
      <NavLink to={"/"}>
        <img
          src={"android-chrome-512x512.png"}
          title={"RETOUR CONNEXION"}
          alt={"CB"}
          width={"512"}
          height={"512"}
        />
      </NavLink>
      <div>
        <NavLink to={"/selection"}>
          <button>
            <FontAwesomeIcon title="VERS SÉLÉCTION" icon={faHouse} fixedWidth />
          </button>
        </NavLink>
      </div>
    </header>
  );
}
