export const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const renderLoader = (
  <div
    className="loading"
    style={{
      alignItems: "center",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      minHeight: "100%",
      minWidth: "100%",
      position: "absolute",
      zIndex: "1000",
    }}
  >
    <h1
      style={{
        fontSize: "1rem",
        fontWeight: "300",
        letterSpacing: "0.5rem",
      }}
    >
      CHARGEMENT
    </h1>
  </div>
);
