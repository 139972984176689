import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../styles/pages/connexion.scss";

export default function Connexion() {
  return (
    <section id="connexion">
      <Helmet>
        <title>Développeur et intégrateur web à Mulhouse | Charly Bihel</title>
        <meta
          name="description"
          content="À la recherche d'un développeur sur Mulhouse ou en télétravail ? Je programme et intègre sur mesure des sites web selon vos besoins."
        />
        <link rel="canonical" href="https://cb-dev.fr/" />
      </Helmet>
      <div className="overlay">
        <div className="profil">
          <div className="glitch">
            <h1 className="line">Charly Bihel</h1>
            {[...Array(9)].map((nb, i) => (
              <p key={i} className="line">
                Charly Bihel
              </p>
            ))}
          </div>
          <h2>Développeur Web</h2>
        </div>
        <Link to="/selection" className="frame">
          <button title="ENTRER">ENTRER</button>
          <div className="angles"></div>
          <span
            className="triangle"
            style={{ animation: animationTriangle4(2) }}
          ></span>
          <span
            className="triangle"
            style={{ animation: animationTriangle3(2.3) }}
          ></span>
          <span
            className="triangle"
            style={{ animation: animationTriangle2(3.2) }}
          ></span>
          <span
            className="triangle"
            style={{ animation: animationTriangle3(2.6) }}
          ></span>
          <span
            className="triangle"
            style={{ animation: animationTriangle1(2.8) }}
          ></span>
          <span
            className="triangle"
            style={{ animation: animationTriangle2(3) }}
          ></span>
        </Link>
      </div>
      <h3>MULHOUSE</h3>
      <div className="background"></div>
    </section>
  );
}

// DEFAULT ANIMATION FOR TRIANGLES
const animationTriangle1 = (i) => {
  return "animationTriangle1 " + (7 + i * 3) + "s infinite";
};

const animationTriangle2 = (i) => {
  return "animationTriangle2 " + (7 + i * 3.2) + "s infinite";
};

const animationTriangle3 = (i) => {
  return "animationTriangle3 " + (7 + i * 3.6) + "s infinite";
};

const animationTriangle4 = (i) => {
  return "animationTriangle4 " + (7 + i * 3.4) + "s infinite";
};
