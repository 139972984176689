import { React } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import "../styles/pages/error.scss";
import "../utils/framedbutton.scss";

export default function Error() {
  return (
    <main>
      <section id="error">
        <Helmet>
          <title>Désolé cette page n'existe pas | Charly Bihel</title>
          <meta name="description" value="Désolé cette page n'éxiste pas." />
        </Helmet>
        <NavLink to={"/selection"}>
          <button>
            <FontAwesomeIcon
              title="RETOUR VERS SÉLÉCTION"
              icon={faHouse}
              fixedWidth
            />
          </button>
        </NavLink>
        <h1>CETTE PAGE N'EXISTE PAS</h1>
      </section>
    </main>
  );
}
