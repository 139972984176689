// REACT
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
// FUNCTIONS
import { renderLoader } from "./utils/functions";

// PAGES
import Connexion from "../src/pages/Connexion";
import Error from "../src/pages/Error";
import Header from "../src/components/Header";
// CSS
import "./index.scss";
import "./utils/animations.scss";
import "./utils/fonts.scss";
import "./utils/framedbutton.scss";
import "./utils/variables.scss";
// PAGES - LAZY
const Selection = lazy(() => import("../src/pages/Selection"));
const Formations = lazy(() => import("../src/pages/Formations"));
const Skills = lazy(() => import("../src/pages/Skills"));
const Realisations = lazy(() => import("../src/pages/Realisations"));
const Contact = lazy(() => import("../src/pages/Contact"));
// ROOT BUILD
const Layout = () => {
  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
    </>
  );
};

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const Root = (
  <Route path="/" element={<Layout />} errorElement={<Error />}>
    <Route index element={<Connexion />} />
    <Route path="selection" element={<Selection />} />
    <Route path="formations" element={<Formations />} />
    <Route path="competences" element={<Skills />} />
    <Route path="realisations" element={<Realisations />} />
    <Route path="contact" element={<Contact />} />
  </Route>
);
const router = createBrowserRouter(createRoutesFromElements(Root));

// RENDER
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Suspense fallback={renderLoader}>
      <HelmetProvider>
        <Helmet prioritizeSeoTags>
          <title>
            Développeur et intégrateur web à Mulhouse | Charly Bihel
          </title>
        </Helmet>
        <RouterProvider router={router} />
      </HelmetProvider>
    </Suspense>
  </React.StrictMode>
);
